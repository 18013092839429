import { createRef } from "react"
import main from "../../img/ServiciosImg/main.png"
import s from "./ServiciosMain.module.css"

export default function ServiciosMain() {

   return (
      <div className={ s.mainImageContainer }>
         <img src={ main } alt="..." />
         <div className={ s.titlesContainer }>
            <h1>Servicios</h1>
            <h4 className={ s.subtitle }>Lo que tu negocio y hogar necesita</h4>
         </div>
      </div>

   )
}