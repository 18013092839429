import { Outlet } from 'react-router-dom'
import NavBar from '../NavBar/NavBar'
import styles from './Home.module.css'
import WhatsappButton from '../WhatappButton/WhatsappButton'

export default function Home() {
   return (
      <div className={ styles.homeCustomContainer }>
         <NavBar />
         <Outlet />

         <WhatsappButton />
      </div>
   )
}