import styles from './Brands.module.css'
//LOGOS DE MARCAS
import hikvision from '../../img/BrandsTiles/hikvision.png'
import dahua from '../../img/BrandsTiles/dahua.png'
import esviz from '../../img/BrandsTiles/esviz.png'
import gvs from '../../img/BrandsTiles/gvs.png'
import paradox from '../../img/BrandsTiles/paradox.png'
import seagate from '../../img/BrandsTiles/seagate.png'
import tenda from '../../img/BrandsTiles/tenda.png'
import toshiba from '../../img/BrandsTiles/toshiba.png'
import western_digital from '../../img/BrandsTiles/western_digital.png'
import zkteco from '../../img/BrandsTiles/zkteco.png'

function BrandContainer({ logo, index }) {
    return (
        <div key={ "brandLogo" + index } className={ styles.brandLogoContainer }>
            <img key={ "logo" + index } src={ logo } className={ styles.logo } alt="logo hikvision" />
        </div>
    )
}

export default function Brands() {
    const logoBrandsArray = [
        hikvision, toshiba, paradox, esviz, tenda, dahua, western_digital, zkteco, seagate, gvs
    ]
    return (
        <div className={ styles.brandsContainer }>
            <h1 className={ styles.title } >Nuestras marcas</h1>
            <div className={ styles.brandLogosContainer }>
                {
                    logoBrandsArray.map((logo, index) =>
                        <BrandContainer key={ "brand" + index } index={ index } logo={ logo } />)
                }
            </div>
        </div >
    )
}

