import AboutUsMain from "../Blocks/AboutUsMain"
import InfoBlock from "../Blocks/InfoBlock"
import TextInfoImg from "../Blocks/NuestraMision.js"

export default function AboutUs() {
   return (
      <div>
         <AboutUsMain />
         <InfoBlock />
         <TextInfoImg />


      </div>
   )
}