import { Route, useParams } from 'react-router-dom';
import ServiciosMain from "../Blocks/ServiciosMain"
import ServiciosSelector from "../ServiciosSelector/ServiciosSelector"
import Carousel from '../Carousel/Carousel';
// import Footer from '../Blocks/Footer.js'

export default function Servicios() {
   const { servicio } = useParams()

   return (
      <div>
         {/* <Carousel /> */ }
         <ServiciosMain />
         <ServiciosSelector servicio={ servicio } />

      </div>
   )
}