import { useState, useEffect } from "react"

// LOGOS CLIENTES
import cosechar from "../../img/NuestrosClientes/cosechar.png"
import distrisagi from "../../img/NuestrosClientes/distrisagi.png"
import llanoequipos from "../../img/NuestrosClientes/llanoequipos.png"
import tubenss from "../../img/NuestrosClientes/tubenss.png"
import unidadRenal from "../../img/NuestrosClientes/unidadRenal.png"
// ESTILOS
import s from "./NuestrosClientesSlider.module.css"

const listUniquesImages = [cosechar, distrisagi, llanoequipos, tubenss, unidadRenal]

export default function NuestrosClientesSlider() {
	const [listImages, setListImages] = useState([...listUniquesImages])
	const [actualSlide, setActualSlide] = useState(0)
	const itemsPerPage = window.innerWidth < 600 ? 2 : 4
	// console.log(window.innerWidth)

	useEffect(() => {
		const interval = setInterval(() => {
			irAdelante()
		}, 5100)

		return () => clearInterval(interval)

	}, [actualSlide]
	)

	function irAdelante() {
		// console.log("ir adelante")
		setActualSlide((prev) => {
			if (prev >= listImages.length - 1) return 0
			return prev + 1
		})

		setListImages((prev) => {
			return [...prev, prev[prev.length - listUniquesImages.length]]
		})


	}

	function irAtras() {
		actualSlide <= 0 ?
			setActualSlide(listImages.length - 1) :
			setActualSlide((prev) => prev - 1)
	}
	// EL WIDTH DEFINE CUANTAS IMAGENES PODEMOS VER EN EL SLIDER (UN WIDTH DE 50% NOS PERMITE VER 2 IMÁGENES)
	// EL MARGIN-LEFT ES NECESARIO PARA HACER AVANZAR DE IMAGEN

	const width = `${(listImages.length) * 100 / itemsPerPage}%`
	const marginLeft = `-${actualSlide * 100 / itemsPerPage}%`
	return (
		<div className={ s.nuestrosClientesContainer }>
			<h1 className={ s.title }>Nuestros clientes</h1>
			<div className={ s.relative } >
				<div className={ s.containerSlider }>
					<div style={ { width: width, marginLeft: marginLeft } } className={ s.slider }>
						{
							listImages.map((imagen, index) => <SliderSection key={ `imgSlider${index}` } imagen={ imagen } />)
						}
					</div>
				</div>
				<div onClick={ irAdelante } className={ s.slider_btn_right }>{ ">" }</div>
				<div onClick={ irAtras } className={ s.slider_btn_left }>{ "<" }</div>
			</div>
		</div>
	)
}

function SliderSection({ imagen }) {
	return (
		<div className={ s.slider_section }>
			<img className={ s.slider_img } src={ imagen } alt="slider_image"></img>
		</div>
	)
}