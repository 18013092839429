import emailJs from "@emailjs/browser"
import { ToastContainer, toast } from 'react-toastify';
import { useState } from "react"
import 'react-toastify/dist/ReactToastify.css';

export const FormContact = () => {
   const [state, setState] = useState({
      contacto_nombre: "",
      contacto_email: "",
      contacto_celular: "",
      contacto_detalle: ""
   })

   const [isLoadingResponse, setIsLoadingResponse] = useState(false)

   const handleStateChange = (inputName) => {
      console.log(inputName.target.name)
      setState({
         ...state,
         [inputName.target.name]: inputName.target.value
      })
   }

   // Estilos
   const st = {
      paddingTop: "70px",
      paddingBottom: "50px",
      // border: "1px solid blue"
   }

   const notify = () => toast.success("Se han enviado tus datos", {
      theme: "light"
   });

   const submitData = (e) => {
      setIsLoadingResponse(!isLoadingResponse)
      e.preventDefault()
      emailJs.send("service_0txt4wr", "template_dytzpsq", { ...state }, 'sc1NAKn7E6pHeWv0N')
         .then((result) => {
            setIsLoadingResponse(false)
            toast.success("Hemos enviado tu información 👍🏼")
            setState(() => ({
               contacto_nombre: "",
               contacto_email: "",
               contacto_celular: "",
               contacto_detalle: ""
            }))
            console.log(result.text);
         }, (error) => {
            setIsLoadingResponse(false)

            toast.error("Hubo un error al enviar tu información :(")
            console.log(error.text);
         });
   }


   return (
      <form onSubmit={ submitData } style={ st } className="container col-sm-10 col-md-4">
         <div className="mb-3 ">


            <h3 className="fs-3">Déjanos tus datos y te contactaremos</h3>

            <br></br>

            <input onChange={ handleStateChange } value={ state.contacto_nombre } name="contacto_nombre" type="text" className="form-control" id="exampleFormControlInput1" placeholder="nombre" />

            <br></br>

            <input onChange={ handleStateChange } value={ state.contacto_email } name="contacto_email" type="email" className="form-control" id="exampleFormControlInput1" placeholder="email" />

            <br></br>

            <input onChange={ handleStateChange } value={ state.contacto_celular } name="contacto_celular" type="number" className="form-control" id="exampleFormControlInput1" placeholder="celular" />

         </div>

         <div className="mb-3">
            <label className="form-label">Cuéntanos cómo podemos ayudarte</label>
            <textarea onChange={ handleStateChange } name="contacto_detalle" className="form-control" id="exampleFormControlTextarea1" rows="3" value={ state.contacto_detalle } />
         </div>

         <button onClick={ submitData } disabled={ isLoadingResponse } type="submit" className="btn btn-outline-primary btn-lg">
            { isLoadingResponse ? <div class="spinner-border text-primary" role="status" />
               : "ENVIAR"
            }
         </button>

         <ToastContainer theme="light" position="bottom-right" />
      </form>
   )
}
