import { NavLink } from 'react-router-dom'
import logoBrand from '../../img/logoBrand.png'
import styles from './NavBar.module.css'
import './NavBar.css'

import BarritaContacto from "../Blocks/BarritaContacto.js";
// ESTABLECE COLOR AL LINK ACTIVO
const getColorIfActive = ({ isActive }) => {
   return isActive ? styles.activeNavLink : styles.navLink
}

export default function NavBar() {
   return (
      <nav className="navbar justify-center navbar-expand-lg p-3 px-2 d-flex justify-content-between navBarCustomStyle">
         <div className="container-fluid">

            <img width='160px' src={ logoBrand } alt='logo' />

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
               <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse navBarCustomLinksContainer" id="navbarNav">
               <ul className="navbar-nav ulCustom">
                  <li className="nav-item navItemCustom">
                     <NavLink className={ getColorIfActive }
                        to='/'>Vitech</NavLink>
                  </li>
                  <li className="nav-item navItemCustom">
                     <NavLink className={ getColorIfActive }
                        to='/servicios'>Servicios</NavLink>
                  </li>
                  <li className="nav-item navItemCustom">
                     <NavLink className={ getColorIfActive }
                        to='/sobre-nosotros'>Sobre nosotros</NavLink>
                  </li>
                  <li className="nav-item navItemCustom">
                     <div >
                        <NavLink className={ styles.contactUsLinkContainer }
                           to='/contacto'>Contáctanos</NavLink>
                     </div>
                  </li>
               </ul>

            </div>


         </div>
      </nav>
   )
}