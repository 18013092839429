import main from "../../img/SobreNosotros/aboutUsmain.jpg"
import s from "./AboutUsMain.module.css"

export default function AboutUs() {

   return (
      <div className={ s.mainImageContainer }>
         <div className={ s.boxTransp }></div>
         <img src={ main } alt="..." />
         <div className={ s.titlesContainer }>
            <h1>Sobre nosotros</h1>
            <h4 className={ s.subtitle }>Un poco más acerca de Vitech</h4>
         </div>
      </div>
   )
}