import { Navigate, useNavigate } from "react-router-dom"
import s from "./ServiciosSelector.module.css"
import { servicesItems } from "../text/servicios.js"

// COMPONENTES
import ServiceBlock from "../ServiceBlock/ServiceBlock"
import { createRef } from "react"

// SERVICIOS
let servicesRoutesComponents = {} // OBJETO CON LOS COMPONENTES DE CADA SERVICIO
servicesItems.forEach((serv => {
   const componentRoute = serv.route
   servicesRoutesComponents[componentRoute] = <ServiceBlock service={ serv } />
}))

export default function ServiciosSelector({ servicio }) {
   let navigate = useNavigate()
   let refServiceSelector = createRef()

   function scrollToServiceSelector() {
      const positionY = refServiceSelector.current.getBoundingClientRect().top
      positionY !== 0 ?
         window.scroll(0, positionY + window.scrollY) :
         console.log("SIN EFECTO EN SCROLL");

   }

   const getStyleForServiceContainer = (serviceItemm) => {
      let styles = { width: `${100 / servicesItems.length}%` };   // DIVIDIMOS EQUITATIVAMENTE EL ANCHO DE LA PANTALLA (WIDTH) PARA LA CANTIDAD DE ITEMS
      if (serviceItemm.route === servicio)
         styles.backgroundColor = "var(--vitech-blue-darker)"
      return styles
   }

   return (
      <div ref={ refServiceSelector } className={ s.mainContainer }>
         <div className={ s.navContainer }>
            {
               servicesItems.map((service, index) => {
                  return (
                     <div
                        style={ getStyleForServiceContainer(service) }
                        onClick={ () => { scrollToServiceSelector(); navigate("/servicios/" + service.route) } }
                        key={ "service" + index }
                        className={ s.serviceContainer }
                     >
                        <img height="32px" src={ service.icon } alt={ "servicio" + index } />
                        <span>{ service.title }</span>
                     </div>
                  )
               })
            }
         </div>

         {
            servicesRoutesComponents[servicio] || <Navigate to="/servicios/telecomunicaciones" />
         }

      </div>




   )
}