import bgBlue from "../../img/SobreNosotros/bg-blue.jpg"
import mision from "../../img/SobreNosotros/mision.png"
import vision from "../../img/SobreNosotros/vision.png"


export const sobreNosotros = {
   image: bgBlue,
   title: "¿Quienes somos?",
   description: "Somos una empresa en el area de la tecnologia, dedicada a brindar asistencia en el sector de las TICs. Actualmente nuestra capacidad operativa nos permite ofrecer un servicion profesional, optimizando los tiempos de respuesta de nuestros clientes."
}

export const nuestraVision = {
   image: vision,
   title: "Nuestra Misión",
   description: "Somos una empresa en el area de la tecnologia, dedicada a brindar asistencia en el sector de las TICs. Actualmente nuestra capacidad operativa nos permite ofrecer un servicion profesional, optimizando los tiempos de respuesta de nuestros clientes."
}

export const nuestraMision = {
   image: mision,
   title: "Nuestra Visión",
   description: "Somos una empresa en el area de la tecnologia, dedicada a brindar asistencia en el sector de las TICs. Actualmente nuestra capacidad operativa nos permite ofrecer un servicion profesional, optimizando los tiempos de respuesta de nuestros clientes."
}

