import s from "./ServiceBlock.module.css"

export default function ServiceBlock({ service }) {


   function SubItemBlock({ item, index }) {

      const defineBackGroundColor = () => {
         if (item.color) return { backgroundColor: item.color }

         return index === 0 || index % 2 === 0 ?
            { backgroundColor: "var(--color-white1)" } :
            { backgroundColor: "white", flexDirection: "row-reverse" }
      }

      return (
         <div style={ defineBackGroundColor() } className={ s.subItemBlockContainer }>
            <div className={ s.subItemImageContainer }>
               <img src={ item.image } alt="..." />
            </div>
            <div className={ s.subItemTextContainer }>
               <h5>{ item.title }</h5>
               <p>{ item.description }</p>

            </div>
         </div>
      )
   }


   return (
      <div className={ s.mainContainer }>
         <div className={ s.headerContainer }>
            <div className={ s.backOpacity } ></div>
            <img src={ service.headerBackGround } alt="..." />
            <div className={ s.headerTextContainer }>
               <h1> { service.title }</h1>
               <p>{ service.headerDescription }</p>
            </div>


         </div>
         {
            service.subItems.map((item, index) => {
               return <SubItemBlock key={ "subtitem" + index } item={ item } index={ index } />
            })
         }


      </div>
   )
}