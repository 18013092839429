import s from "./InfoBlock.module.css"
import { sobreNosotros } from "../text/aboutUs"
import bgBlue from "../../img/SobreNosotros/bg-blue2.jpg"
import vitechLogo from "../../img/SobreNosotros/vitech-shadow.png"

export default function InfoBlock({ service }) {

   return (
      <div className={ s.mainContainer }>
         <div className={ s.headerContainer }>
            {/* <div className={ s.backOpacity } ></div> */ }
            <img src={ bgBlue } alt="..." />
            <div className={ s.headerTextContainer }>
               <img height={ "145px" } src={ vitechLogo } alt="blue-bg" />
               <h1> { sobreNosotros.title }</h1>
               <p>{ sobreNosotros.description }</p>
            </div>
         </div>
      </div>
   )
}