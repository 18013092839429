import { NavLink } from "react-router-dom"
import logoVitech from "../../img/logoVitech-gray.png"
import s from "./Footer.module.css"

export default function Footer() {
    return (
        <div className={ s.footer } >

            <div className={ s.footer1 } >
                <img height={ "50px" } src={ logoVitech } alt="logo" />
            </div>

            <div className={ s.footer2 } >
                <NavLink className={ s.link } to="/">Servicios</NavLink>
                <NavLink className={ s.link } to="/">Sobre nosotros</NavLink>
                <NavLink className={ s.link } to="/">Escríbenos</NavLink>
            </div>

            <div className={ s.footer3 } >
                <span>Contacto</span>
                <span>+57 314 797 3494</span>
                <span>ite.casanare@gmail.com</span>
                <span>Yopal, Casanare CO</span>
            </div>
        </div>
    )
}