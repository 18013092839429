import ms1 from '../../img/MainSlider/ms1.png'
import ms3 from '../../img/MainSlider/ms3.png'
import './MainSlider.css'

export default function MainSlider() {
    return (
        <div id="mainSlider" className="carousel slide" data-bs-ride="false">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#mainSlider" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#mainSlider" data-bs-slide-to="1" aria-label="Slide 2"></button>
            </div>
            <div className="carousel-inner sliderContainer">
                <div className="carousel-item active sliderContainer">
                    <div className='darkImg'></div>
                    <img src={ ms1 } className="d-block w-100 img-fluid slideImg" alt="..." />
                    <div className="carousel-caption sliderTextContainer">
                        <h5 className='fs-1' >Comprometidos con la transformación digital</h5>
                        <br></br>
                        <p className='paragraphSlide'>Vitech le ayuda a ser parte de la revolución tecnológica</p>
                    </div>
                </div>

                <div className="carousel-item sliderContainer">
                    <div className='darkImg'></div>
                    <img src={ ms3 } className="d-block w-100 img-fluid slideImg" alt="..." />
                    <div className="carousel-caption sliderTextContainer">
                        <h5 className='titleSlide' >Servicios orientados exclusivamente a las necesidades de nuestros clientes</h5>
                        <br></br>
                        <p className='paragraphSlide'>Póngase en contacto, nosotros le asesoramos</p>
                    </div>
                </div>

                {/* <div className="carousel-item sliderContainer">
                    <div className='darkImg'></div>
                    <img src={ ms2 } className="d-block w-100 img-fluid slideImg" alt="..." />
                    <div className="carousel-caption sliderTextContainer">
                        <h5 className='titleSlide' >Servicios orientados exclusivamente a las necesidades de nuestros clientes</h5>
                        <br></br>
                        <p className='paragraphSlide'>dlkafsdj</p>
                    </div>
                </div> */}




            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#mainSlider" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#mainSlider" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>

    )

}