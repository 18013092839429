import styles from "./ContactUs.module.css"
import ContactUsMain from "./ContactUsMain"
import { FormContact } from "./FormContact"

export const ContactUs = () => {



   return (
      <div className={ styles.main }>
         <ContactUsMain />
         <FormContact />
      </div>
   )
}


