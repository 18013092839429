import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import AboutUs from './components/AboutUs/AboutUs';
import Home from './components/Home/Home.js';
import Main from './components/Main/Main';
import Servicios from './components/Servicios/Servicios';
import { ContactUs } from './components/Blocks/ContactUs';
import Footer from './components/Blocks/Footer';

function App() {


   return (
      <div className="App">
         <Routes>
            {/* // RUTA HOME */ }
            <Route path='/' element={ <Home /> }>
               <Route path='/' element={ <Main /> } />
               <Route path='servicios' element={ <Servicios /> } />
               <Route path='servicios/:servicio' element={ <Servicios /> } />
               <Route path='sobre-nosotros' element={ <AboutUs /> } />
               <Route path='contacto' element={ <ContactUs /> } />

            </Route>
         </Routes>
         <Footer />
      </div >
   );
}

export default App;
