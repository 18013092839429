import Brands from "../Blocks/Brands.js";
import MainSlider from "../Blocks/MainSlider.js";
import NuestrosClientesSlider from "../Blocks/NuestrosClientesSlider.js";
// import NuestrosClientes from "../Blocks/NuestrosClientes.js";
import Soluciones from "../Blocks/Soluciones.js";
import Footer from "../Blocks/Footer.js"
import BarritaContacto from "../Blocks/BarritaContacto.js";


export default function Main() {
   return (
      <div>
         <MainSlider />
         <Brands />
         <Soluciones />
         <NuestrosClientesSlider />

      </div>
   )
}