import s from "./NuestraMision.module.css"
import { nuestraVision, nuestraMision } from "../text/aboutUs"

export default function TextInfoImg() {
   const bg = "var(--color-white1)"
   const direction = "row-reverse"


   return (
      <>
         <div style={ { backgroundColor: bg } } className={ s.subItemBlockContainer }>
            <div className={ s.subItemImageContainer }>
               <img src={ nuestraVision.image } alt="..." />
            </div>
            <div className={ s.subItemTextContainer }>
               <h5>{ nuestraVision.title }</h5>
               <p>{ nuestraVision.description }</p>

            </div>
         </div>

         <div style={ { backgroundColor: bg, flexDirection: direction } } className={ s.subItemBlockContainer }>
            <div className={ s.subItemImageContainer }>
               <img src={ nuestraMision.image } alt="..." />
            </div>
            <div className={ s.subItemTextContainer }>
               <h5>{ nuestraMision.title }</h5>
               <p>{ nuestraMision.description }</p>

            </div>
         </div >
      </>
   )

}