import main from "../../img/Contacto/manitas.png"
import s from "./ContactUsMain.module.css"

export default function ContactUsMain() {

   return (
      <div className={ s.mainImageContainer }>
         <div className={ s.boxTransp }></div>
         <img src={ main } alt="..." />
         <div className={ s.titlesContainer }>
            <h1>Contáctanos</h1>
            <h4 className={ s.subtitle }>¿Necesitas saber más acerca de nuestros servicios?</h4>
         </div>
      </div>

   )
}