import styles from "./Whatsapp.module.css"
import whatsappLogo from "../../img/whatsapp.png"

export default function WhatsappButton() {


   return (
      <div className={ styles.main }  >
         <a rel="noreferrer" href="https://walink.co/ebebb2" target="_blank">
            <img className={ styles.logoStyle } src={ whatsappLogo } alt="whatsapp.link" />
         </a>
      </div>
   )
}
