// ICONOS DE SERVICIOS
import storageIcon from "../../img/ServiciosIconos/server.png"
import hardwareIcon from "../../img/ServiciosIconos/pc.png"
import securityIcon from "../../img/ServiciosIconos/camera.png"
import softwareIcon from "../../img/ServiciosIconos/server.png"
import telecomIcon from "../../img/ServiciosIconos/network.png"

//HEADERS BACKGROUNDS DE SERVICIOS
import telecomBg from "../../img/ServiciosImg/telecomunicaciones-bg.png"
import hardwareBg from "../../img/ServiciosImg/hardware.png"
import securityBg from "../../img/ServiciosImg/seguridad.png"
import serverBg from "../../img/ServiciosImg/servidores/image1-5.png"


// TELECOMUNICACIONES SUBITEMS
import cableadoEstructurado from "../../img/ServiciosImg/telecomunicaciones/cables2.webp"
import radioEnlaces from "../../img/ServiciosImg/telecomunicaciones/antenas.png"

// HARDWARE Y MANTENIMIENTO SUBITEMS
import mantenimiento from "../../img/ServiciosImg/hardware/mantenimiento.png"
import venta from "../../img/ServiciosImg/hardware/venta.png"
import impresora from "../../img/ServiciosImg/hardware/impresora.png"

// SEGURIDAD SUBITEMS
import videovigilancia from "../../img/ServiciosImg/seguridad/seguridad.jpg"
import alarmas from "../../img/ServiciosImg/seguridad/alarma.png"

// SERVIDORES SUBITEMS
import server from "../../img/ServiciosImg/servidores/servers.png"

const teleco = {
   route: "telecomunicaciones",
   icon: telecomIcon,
   headerBackGround: telecomBg,
   title: "Telecomunicaciones",
   headerDescription: "Las telecomunicaciones para una empresa se han convertido en un requisito moderno, incluso para la vida actual en el hogar y en Vitech reconocemos su importancia.",
   subtitle: "Lo que te ofrecemos",
   subItems: [{
      image: cableadoEstructurado,
      title: "CABLEADO ESTRUCTURADO",
      description: "Mejoramos y expandimos la infraestructura de la red de tu empr|esa, haciendo más fácil la interconexión entre dispositivos, y permitiendo compartir dispositivos por medio de la red como impresoras.",
      buttonText: "¿QUIERES SABER MÁS"
   },
   {
      image: radioEnlaces,
      title: "RADIO ENLACES",
      description: "Ofrecemos acceso a internet en zonas remotas donde el servicio es limitado o nulo, mediante un enlace de ondas de radio las regiones ubicadas en zonas rurales pueden gozar de conexión a internet.",
      buttonText: "¿QUIERES SABER MÁS"
   }]
}

const hardware = {
   route: "hardware-y-mantenimiento",
   icon: hardwareIcon,
   headerBackGround: hardwareBg,
   title: "Soporte técnico",
   headerDescription: "En Vitech, conocemos el papel fundamental de los equipos electrónicos en el día a día de la vida moderna, es por eso que:",
   subtitle: "Ofrecemos",
   subItems: [{
      image: mantenimiento,
      title: "MANTENIMIENTO",
      description: "En los equipos de trabajo activos como portátiles y computadoras de escritorio e incluso servidores con el fin de garantizar su buen desempeño. Reparación y reemplazo de coponentes.",
      buttonText: "¿QUIERES SABER MÁS"
   },
   {
      image: venta,
      title: "VENTA Y ALQUILER DE EQUIPOS",
      description: "Te ofrecemos equipos al mejor precio o la posibilidad de alquilar uno, ya sea una computadora portátil, proyector o impresora.",
      buttonText: "¿QUIERES SABER MÁS"
   },
   {
      image: impresora,
      title: "SUMINISTRO DE CONSUMIBLES DE IMPRESORA",
      description: "Contamos con suministro o recargas de tóner para impresoras láser y tintas para impresoras de inyección, mantenimiento y reparación de las mismas y su configuración en los puestos de trabajo.",
      buttonText: "¿QUIERES SABER MÁS"
   }
   ]
}

const security = {
   route: "seguridad",
   icon: securityIcon,
   headerBackGround: securityBg,
   title: "Sistemas de seguridad",
   headerDescription: "Es importante tomar medidas de seguridad, con el fin de proteger el inmueble y sus bienes. En Vitech contamos con servicios orientados a la protección de  tu negocio y hogar",
   subtitle: "Lo que ofrecemos",
   subItems: [{
      image: videovigilancia,
      title: "SISTEMAS DE VIDEO-VIGILANCIA",
      description: "Si necesitas supervisar en tiempo real las instalaciones de tu negocio o tu vivienda, el uso de múltiples cámaras de vigilancia es la opción ideal.",
      buttonText: "¿QUIERES SABER MÁS",
   },
   {
      image: alarmas,
      title: "SISTEMAS DE ALARMAS",
      description: "Si necesitas seguridad extra, un sistema de alarma es lo que necesitas.",
      buttonText: "¿QUIERES SABER MÁS",

   }]
}

const storage = {
   route: "almacenamiento",
   icon: storageIcon,
   title: "Almacenamiento",
   description: "Las telecomunicaciones son imprescidibles para una empresa y se han convertido en un requisito moderno para la vida actual en el hogar. En Vitech reconocemos su importancia y ofrecemos diferente servicios que se ajustan a las necesidades de cada cliente.",
   subtitle: "Lo que ofrecemos",
   subItems: []
}

const servers = {
   route: "software",
   icon: softwareIcon,
   title: "Servidores",
   headerBackGround: serverBg,
   headerDescription: "Ya sea como una fuente centralizada para el almacenamiento de archivos, acceso a impresoras o bases de datos, los servidores refuerzan la productividad de la empresa permitiendo una mejor comunicacion y organización en los equipos de trabajo.",
   subtitle: "Lo que ofrecemos",
   subItems: [{
      image: server,
      title: "VENTA, INTALACIÓN Y ADMINISTRACIÓN",
      description: "Cada empresa necesita un servidor a medida, si tu empresa necesita ya sea un servidor de carpetas de trabajo o uno remoto al cuál tus empleados se conectan a realizar sus actividades diarias, Vitech lo hace por ti.",
      buttonText: "¿QUIERES SABER MÁS",
   }, {
      image: server,
      title: "VENTA, INTALACIÓN Y ADMINISTRACIÓN",
      description: "Cada empresa necesita un servidor a medida, si tu empresa necesita ya sea un servidor de carpetas de trabajo o uno remoto al cuál tus empleados se conectan a realizar sus actividades diarias, Vitech lo hace por ti.",
      buttonText: "¿QUIERES SABER MÁS",
   }
   ]
}

export const servicesItems = [teleco, hardware, security, servers]