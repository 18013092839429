import styles from './Soluciones.module.css'
import telecomunicaciones from '../../img/SolucionesTiles/telecomunicaciones.png'
import seguridad from '../../img/SolucionesTiles/seguridad.png'
import suministros from '../../img/SolucionesTiles/suministros.png'
import asesoramiento from '../../img/SolucionesTiles/asesoramiento.png'

function SolucionTile({ img, title, detail }) {
   return (
      <div className={ styles.solucionTile } >
         <img src={ img } className={ styles.solucionImg } alt="..." />
         <div className={ styles.textTile }>
            <h3 className="fs-4">{ title }</h3>
            <p>{ detail }</p>
         </div>
      </div >

   )
}


export default function Soluciones() {
   return (
      <div className={ styles.solucionesContainer }>
         <br></br>
         <h1 className="fs-2 my-3">Vitech, tu compañero de soluciones tecnológicas</h1>

         <p >Creemos en el soporte personalizado en cada uno de nuestros servicios.  Nuestra filosofía se basa
            <br></br>
            en ofrecer soluciones convenientes y específicas que se acoplen a las necesidades del cliente.</p>

         <div className={ styles.tilesContainer }>
            <SolucionTile
               img={ asesoramiento }
               title={ "Asesoramiento" }
               detail={ "¿No sabe que soluciones necesita implementar en su negocio u hogar? Nosotros lo asesoramos" }
            />

            <SolucionTile
               img={ telecomunicaciones }
               title={ "Telecomunicaciones" }
               detail={ "La forma de comunicarce del siglo XXI ahora en negocio y hogar" }
            />


         </div>

         <div className={ styles.tilesContainer }>
            <SolucionTile
               img={ seguridad }
               title={ "Seguridad" }
               detail={ "Para garantizar la vigilancia en una zona o perímetro" }
            />

            <SolucionTile
               img={ suministros }
               title={ "Suministro de equipos" }
               detail={ "Sean impresoras, computadoras de mesa o portátiles y piezas para su equipo" }
            />

         </div>

         <br></br>
         <br></br>
         <button className={ styles.buttonVerServicios }>Conoce más</button>
         <br></br>
         <br></br>

      </div>)
}